import React, { useEffect, useState } from "react";
import { FaFileSignature } from "react-icons/fa";
import HeaderTopBar from "../../HeaderTopBar";
import { useNavigate } from "react-router-dom";
import { BiDetail } from "react-icons/bi";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";

const Caregiver = () => {
  const [careGiverList, setCaregiverList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lgaList, setLgaList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filteredCareGiverList, setFilteredCareGiverList] = useState([]);

  const searchFormik = useFormik({
    initialValues: {
      careGiverId: "",
      childID: "",
      lga:"",
      ward: "",
      dateOfBirth: "",
      dateEnrolled: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setIsFilter(true);
      const filteredData = careGiverList.filter((careGiver) => {
        return (
          (Number(values.careGiverId) === 0 ||
            careGiver.careGiverId === Number(values.careGiverId)) ||
          (values.childID === 0 || careGiver.childID === values.childID) ||
          (values.ward === 0 || careGiver.ward === values.ward) ||
          (values.dateOfBirth === null ||
            careGiver.dateOfBirth === values.dateOfBirth) ||
          (values.dateEnrolled === null ||
            careGiver.dateEnrolled === values.dateEnrolled) ||
          (values.lga === 0 || careGiver.lga === values.lga)
        );
      });
      setFilteredCareGiverList(filteredData);
    },
  });

  const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/users/lga/`;
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/users/ward/`;
  const getLga = () => {
    setLoading(true);
    axios
      .get(lgaUrl, axiosConfig)
      .then((response) => {
        setLgaList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  //Get Ward
  const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  useEffect(() => {
    getLga();
    getWard();
  }, []);
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/caregiver`;
  const token = localStorage.getItem("token");

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [deleteUrl, setDeleteUrl] = useState(
    `${process.env.REACT_APP_MAIN_URL}/caregivers/caregiver`
  );

  //Delete function
  const handleDeleteClick = () => {
    const selectedIDs = selectedData.map((row) => row.careGiverId);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(deleteUrl, { caregiver_id: selectedIDs }, config)
      .then((response) => {
        toast.success("Deleted successfully");
        setSelectedData([]);
        fetchData();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  //Get Dashboard data
  const fetchData = () => {
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setCaregiverList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (searchTerm == "") {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const navigate = useNavigate();
  const handleClick = (id) => {
    const detailLink = `/caregiver?id=${id}`;
    navigate(detailLink);
  };

  // Define your click handler for the "Delete" button
  const handleSearchClick = (e) => {
    setLoading(true);
    setSearchTerm(e.target.value);
    const filteredCareGivers = careGiverList.filter((caregiver) =>
      ['careGiverId', 'firstname', 'lastname', 'otherNames', 'dateOfBirth', 'phone', 'ward', 'lga'].some((key) => {
        const value = caregiver[key];
        if (typeof value === "string" || typeof value === "number") {
          const lowerCaseSearchTerm = e.target.value.toLowerCase();
          const lowerCaseValue = String(value).toLowerCase();
          return lowerCaseValue.startsWith(lowerCaseSearchTerm);
        }
        return false;
      })
    );
    setCaregiverList(filteredCareGivers);
    setLoading(false);
  };
  
  

  // Define your change handler for the search input
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handler for when the table header checkbox is clicked
  const handleTableHeaderCheckboxClick = () => {
    if (selectedData.length === careGiverList.length) {
      setSelectedData([]);
    } else {
      setSelectedData(careGiverList);
    }
  };

  // Handler for when an individual checkbox is clicked
  const handleRowCheckboxClick = (rowData) => {
    if (selectedData.includes(rowData)) {
      setSelectedData(
        selectedData.filter((selectedRow) => selectedRow !== rowData)
      );
    } else {
      setSelectedData([...selectedData, rowData]);
    }
  };

  //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const tableData = isFilter ? filteredCareGiverList : careGiverList;
  const paginatedData = tableData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(tableData.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      {loading && <Loader />}
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Enrollment"}
        subtitle={"caregiver"}
      />

      <form onSubmit={searchFormik.handleSubmit}>
        <div className="w-full mt-2">
          <section className="container px-4 mx-auto">
            <div className="flex flex-col">
              {/* Filter start */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6 bg-white rounded-lg shadow-md lg:grid-cols-3">
                <div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Caregiver ID:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="text"
                        id="careGiverId"
                        name="careGiverId"
                        placeholder="Enter ID"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.careGiverId}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Child ID:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="text"
                        id="childID"
                        name="childID"
                        placeholder="Enter ID"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.childID}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className="text-gray-700 col-span-1 flex items-center text-sm">
                      Ward
                    </label>
                    <div className="col-span-3">
                      <select
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400"
                        id="ward"
                        name="ward"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.ward?.id}
                      >
                        <option value="">Select a Ward</option>
                        {wardList.map((lga) => (
                          <option key={lga.id} value={lga.id}>
                            {lga.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <FormGroup label="LGA" id="vc-given" select /> */}
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Date of Birth:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        placeholder="Enter date of Birth"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.dateOfBirth}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Date enrolled:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="date"
                        id="dateEnrolled"
                        name="dateEnrolled"
                        placeholder="Enter date"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.dateEnrolled}
                        // placeholder={placeholder}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className="text-gray-700 col-span-1 flex items-center text-sm">
                      LGA
                    </label>
                    <div className="col-span-3">
                      <select
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400"
                        id="lga"
                        name="lga"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.lga?.id}
                      >
                        <option value="">Select a LGA</option>
                        {lgaList.map((lga) => (
                          <option key={lga.id} value={lga.id}>
                            {lga.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className=" justify-end mt-1">
                  <button
                    className=" bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                    type="submit"
                  >
                    Filter
                  </button>
                </div>
              </div>

              {/* Filter end */}

              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                    <div className="flex justify-between mb-1 p-2">
                      <button
                        type="button"
                        onClick={handleDeleteClick}
                        className="bg-red-500 text-white rounded px-4 py-2"
                      >
                        Delete
                      </button>
                      <div className="flex">
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={handleSearchClick}
                          placeholder="Search..."
                          className="rounded-l border border-r-0 border-gray-300 dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700 px-4 py-2"
                        />
                        {/* <button
                          type="button"
                          onClick={handleSearchClick}
                          className="bg-blue-500 text-white rounded-r px-4 py-2"
                        >
                          Search
                        </button> */}
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            <div className="flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                onChange={handleTableHeaderCheckboxClick}
                                checked={
                                  selectedData.length === careGiverList.length
                                }
                              />

                              <button className="flex items-center gap-x-2">
                                <span>Details</span>

                                {/*  */}
                              </button>
                            </div>
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            CaregiverID
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            First Name
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Last Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Other Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Date of Enrollment
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Phone Number
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Ward
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            LGA
                          </th>

                          <th scope="col" className="relative py-3.5 px-4">
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {paginatedData?.map((val, idx) => (
                          <tr key={idx}>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <input
                                  type="checkbox"
                                  className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                  onChange={() => handleRowCheckboxClick(val)}
                                  checked={selectedData.includes(val)}
                                />
                                <span
                                  onClick={() => {
                                    handleClick(val?.careGiverId);
                                  }}
                                  className="cursor-pointer text-blue-500 hover:underline"
                                >
                                  <BiDetail />
                                </span>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.careGiverId}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                              {val?.firstname}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.lastname}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.otherNames}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.enrolledDate}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.phone}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.ward}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.lga}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {paginatedData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${
                      currentPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))}

                {(currentPage < 1 && paginatedData?.length > 1)  ? (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}   
          </section>
        </div>
      </form>
    </div>
  );
};

export default Caregiver;
