import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaSave } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddTransaction() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const caregiverId = searchParams.get("caregiverId");
  const childID = searchParams.get("childID");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [caregiverDetail, setCaregiverDetail] = useState(null);

  const updateInitialValue = {
    amount: "",
    nextDueDateStart: "",
    eligible_for_cct: "",
    status: "",
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  //Update Method
  const handleSubmit = async (values) => {
    const allValue = {
      ...values,
      child: caregiverDetail?.child,
      // detail:"hello"
    };
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/${caregiverId}/childs/${childID}/conditional-cashs/${id}/`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 200) {
        setBtnLoading(false);
        toast.success("Saved Sucessfully");
        formik.resetForm();
        if (id) {
          fetchData(id);
        }
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error("Something went wrong");
    }
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/${caregiverId}/childs/${childID}/conditional-cashs/${id}/`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setCaregiverDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const inputConfigs = [
    {
      label: "Card ID",
      id: "enrollmentId",
      name: "enrollmentId",
      type: "text",
      placeholder: "Enter card ID",
    },
    {
      label: "Amount",
      id: "amount",
      name: "amount",
      type: "text",
      placeholder: "Enter Amount",
    },
    {
      label: "Eligible for CCT",
      id: "eligible_for_cct",
      name: "eligible_for_cct",
      type: "text",
      placeholder: "Enter value",
    },
    {
      label: "Date Received",
      id: "nextDueDateStart",
      name: "nextDueDateStart",
      type: "date",
      placeholder: "Enter Received Date",
    },
    {
      label: "Status",
      id: "status",
      name: "status",
      type: "text",
      placeholder: "Status",
    },
  ];
  return (
    <div className="mt-16">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-5">
        <div className="p-6">
          <div className="grid md:grid-cols-4 lg:grid-cols-4 sm:grid-cols-4 gap-4">
            <div className="lg:col-span-1 md:col-span-1 sm:col-span-1">
              <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                <AiOutlineUser size={64} color="white" />
              </div>
            </div>
            <div className="sm:col-span-3 md:col-span-3 lg:col-span-3">
              <div className="text-gray-600 dark:text-gray-400 text-sm">
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">
                    Caregiver ID
                  </span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.careGiverId}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">Name</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.firstname}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">Gender</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.gender}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">Phone</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.phone}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">
                    Occupation
                  </span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.occupation}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4 font-semibold">State</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.state}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4 font-semibold">LGA</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.lga}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4 font-semibold">Ward</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.ward}
                  </span>
                </div>
                <div className="flex justify-between font-bold border-b border-gray-300 mt-5">
                  <span className="text-sm lg:w-1/4 md:w-1/4 sm:w-2/4 font-bold">
                    New Transaction
                  </span>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-1 gap-2 text-xs mt-2">
                    {inputConfigs.map((config, index) => (
                      <div
                        key={index}
                        className="col-span-1 flex items-center min-w-[50px]"
                      >
                        <label>{config.label}:</label>
                        <input
                          className="ml-2 h-8"
                          type={config.type}
                          id={config.id}
                          name={config.name}
                          placeholder={config.placeholder}
                          value={formik.values[config.name]}
                          onChange={formik.handleChange}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center space-x-4 p-3 pl-28">
                    <button
                      className="bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded-lg flex items-center transition-transform hover:scale-105"
                      type="submit"
                    >
                      {btnLoading ? (
                        <div className="flex items-center">
                          <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        <>
                          <FaSave className="mr-2" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
