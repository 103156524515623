import axios from "axios";
import Chart from "chart.js/auto";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import { FaSpinner } from "react-icons/fa";

export default function Dashboard() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(null);
  const [chart, setChartData] = useState(null);

  const updateInitialValue = {
    region: "",
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {},
    enableReinitialize: true,
    validateOnChange: false,
  });

  const chartUrl = `${process.env.REACT_APP_MAIN_URL}/users/sales_report`;

  //Get Dashboard data
  const getChartData = (values) => {
    setLoading(true);
    axios
      .post(chartUrl, { region: values?.region }, axiosConfig)
      .then((response) => {
        setChartData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in Region");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (formik.values) {
      getChartData(formik.values);
    }
  }, [formik.values]);

  console.log("Formik.values", formik.values);
  const month = chart?.length > 0 ? chart?.map((item) => item?.month) : [];
  const count = chart?.length > 0 ? chart?.map((item) => item?.total) : [];

  const chartData = {
    labels: month,
    datasets: [
      {
        label: "Income",
        data: count,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192,192, 1)",
      },
    ],
  };

  React.useEffect(() => {
    if (chart?.length > 0) {
      const ctx = document
        .getElementById("incomeExpenseChart")
        .getContext("2d");
      let myChart;

      const destroyChart = () => {
        if (myChart) {
          myChart.destroy();
        }
      };
      // Create or update the chart
      destroyChart();
      myChart = new Chart(ctx, {
        type: "bar",
        data: chartData,
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      return () => {
        destroyChart();
      };
    }
  }, [chart]);

  // Pie Chart
  const data = {
    labels: ["Total Paid", "Pending", "Unpaid"],
    datasets: [
      {
        data: [
          dashboardData?.total_paid,
          dashboardData?.total_pending,
          dashboardData?.total_unpaid,
        ],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
  };
  const month2 =
    dashboardData?.monthly_enrollment?.length > 0
      ? dashboardData?.monthly_enrollment?.map((item) => item?.month)
      : [];
  const count2 =
    dashboardData?.monthly_enrollment?.length > 0
      ? dashboardData?.monthly_enrollment?.map((item) => item?.count)
      : [];

  console.log("month2", month2);

  // Enrollment chart
  const chartData1 = {
    labels: month2,
    datasets: [
      {
        label: "Enrollment",
        data: count2,
        backgroundColor: "rgba(75, 192, 192, 0.5)",
        borderColor: "rgba(75, 192, 192, 1)",
      },
    ],
  };

  useEffect(() => {
    if (dashboardData?.monthly_enrollment?.length > 0) {
      const ctx = document.getElementById("enrollmentChart").getContext("2d");
      let myChart;
      const destroyChart = () => {
        if (myChart) {
          myChart.destroy();
        }
      };

      // Create or update the chart
      destroyChart();
      myChart = new Chart(ctx, {
        type: "bar",
        data: chartData1,
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      });

      return () => {
        destroyChart();
      };
    }
  }, [dashboardData?.monthly_enrollment]);
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/users/dashboard`;
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const fetchData = () => {
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setDashboardData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  console.log("Dashboard data", dashboardData);

  const regionUrl = `${process.env.REACT_APP_MAIN_URL}/users/region/`;
  const [regionList, setRegionList] = useState(null);

  //Get Dashboard data
  const getRegion = () => {
    setLoading(true);
    axios
      .get(regionUrl, axiosConfig)
      .then((response) => {
        setRegionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in Region");
        setLoading(false);
      });
  };
  useEffect(() => {
    getRegion();
  }, []);

  return (
    <div>
      {/* {loading && <Loader/>} */}
      <div className="border shadow-lg w-full h-12  flex items-center justify-center text-black font-bold text-2xl rounded-lg">
        Dashboard
      </div>

      {/* Card Start */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 p-4">
        <div className="bg-gradient-to-br from-blue-300 to-blue-500 border border-blue-400 shadow-xl rounded-lg p-2">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-white">
                Total Caregiver
              </h2>
              <p className="text-sm text-gray-100">
                Total:{dashboardData?.total_caregiver || 0}
              </p>
            </div>
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-blue-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v4h-2zm0 6h2v2h-2z" />
              </svg>
            </div>
          </div>
          <div className="border-t border-blue-400 mt-2 text-sm text-white">
            <p>
              {dashboardData?.total_caregiver_added_today || 0} caregiver added
              than yesterday
            </p>
          </div>
        </div>

        <div className="bg-gradient-to-br from-green-300 to-green-500 border border-green-400 shadow-xl rounded-lg p-2">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-white">
                Total EO Activity
              </h2>
              <p className="text-sm text-gray-100">
                Total: {dashboardData?.total_activity || 0}
              </p>
            </div>
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-green-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v4h-2zm0 6h2v2h-2z" />
              </svg>
            </div>
          </div>
          <div className="border-t border-green-400 mt-2 text-sm text-white">
            <p>
              {dashboardData?.total_activity_then_yesterday || 0}% than
              Yesterday
            </p>
          </div>
        </div>

        <div className="bg-gradient-to-br from-purple-300 to-purple-500 border border-purple-400 shadow-xl rounded-lg p-2">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold text-white">
                Total Cash Transferred
              </h2>
              <p className="text-sm text-gray-100">
                Total: {dashboardData?.total_cash_trasfer || 0}
              </p>
            </div>
            <div className="flex items-center justify-center w-12 h-12 rounded-full bg-purple-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-200"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-12h2v4h-2zm0 6h2v2h-2z" />
              </svg>
            </div>
          </div>
          <div className="border-t border-purple-400 mt-2 text-sm text-white">
            <p>
              {dashboardData?.total_cash_transfer_then_yesterday || 0}% than
              yesterday
            </p>
          </div>
        </div>
      </div>
      {/* Chart */}
      <div
        class="bg-white border border-gray-300 shadow-lg rounded-lg lg:p-2 lg:pr-0 lg:ml-2 lg:mt-0 sm:w-full md:w-1/2 w-full sm:h-60"
        style={{ width: "100%", maxWidth: "1200px", height: "500px" }}
      >
        <div class="flex justify-between items-center p-2">
          <select
            id="region"
            name="region"
            className="w-40 border p-1 mb-4"
            onChange={formik.handleChange}
            value={formik.values?.region}
          >
            <option value="">Select a Region</option>
            {regionList?.map((region) => (
              <option key={region.id} value={region.name}>
                {region.name}
              </option>
            ))}
          </select>
        </div>

        {/* <canvas id="incomeExpenseChart" width="600" height="200"></canvas> */}
        <div className="relative">
          <canvas
            id="incomeExpenseChart"
            width="600"
            height="200"
            className="bg-gray-200"
          ></canvas>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>
      </div>
      <div className="lg:pt-4 lg:pl-2 md:pt-4 lg:grid lg:grid-cols-12 gap-2">
        <div
          className={`bg-white border border-gray-300 shadow-lg rounded-lg w-full lg:col-span-6  ${
            window.innerWidth < 1280 ? "w-100%" : "w-full"
          }`}
        >
          {/* Insert your Pie component here */}
          <Pie data={data} options={options} />
        </div>
        <div
          className={`bg-white border border-gray-300 shadow-lg rounded-lg w-full lg:col-span-6 md:mt-4 lg:mt-0 sm:space-y-4 ${
            window.innerWidth < 1280 ? "w-97" : "w-full"
          }`}
        >
          <canvas id="enrollmentChart" width="600" height="300"></canvas>
        </div>
      </div>
    </div>
  );
}
