import React, { Fragment, useState } from "react";
import { HiOutlineCog, HiOutlineSearch } from "react-icons/hi";
import { AiOutlineGlobal } from "react-icons/ai";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";

export default function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const updateInitialValue = {
    currentPassword: "",
    newpPassword: "",
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (values) => {
    const url = `${process.env.REACT_APP_MAIN_URL}/users/password

    `;
    const allValue = {
      ...values,
    };
    try {
      setLoading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(url, allValue, config);
      if (response.status === 200) {
        setLoading(false);
        toast.success("Password updated successfully");

        formik.resetForm();
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.error);
    }
    closeModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    formik.resetForm();
  };

  const navigation = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigation("/login");
  };

  console.log("Formik value", formik.values);

  return (
    <div className="fixed top-0 left-60 right-0 bg-white h-16 px-4 flex justify-between items-center border-b border-gray-300">
      <div className="relative">
        <HiOutlineSearch
          fontSize={20}
          className="text-gray-400 absolute top-1/2 -translate-y-1/2 left-3"
        />
        <input
          type="text"
          placeholder="Search..."
          className="text-sm focus:outline-none active:outline-none h-10 w-[24rem] border border-gray-300 rounded-sm pl-11 pr-4"
        />
      </div>
      <div className="flex items-center gap-2 mr-2">
        <AiOutlineGlobal fontSize={24} />
        <HiOutlineCog fontSize={24} />

        <Menu>
          <div>
            <Menu.Button className="ml-2 inline-flex rounded-full focus:outline-none focus:ring-2 focus:ring-neutral-400">
              <span className="sr-only">Open user menu</span>
              <div
                className="h-10 w-10 rounded-full bg-sky-200 bg-cover bg-no-repeat bg-center"
                style={{
                  backgroundImage:
                    'url("https://t3.ftcdn.net/jpg/05/53/79/60/360_F_553796090_XHrE6R9jwmBJUMo9HKl41hyHJ5gqt9oz.jpg',
                }}
              >
                <span className="sr-only">Salauddin Ayub</span>
              </div>
            </Menu.Button>
          </div>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items className="origin-top-right z-10 absolute right-8 mt-2 w-60 rounded-sm shadow-md p-1 bg-white ring-1 ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <>
                    {isModalOpen ? (
                      <div className=" top-0 left-0 w-full h-full flex items-center justify-center bg-modal-blur">
                        <div className="bg-white p-8 rounded-md shadow-md relative overflow-hidden">
                          <button
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer"
                            onClick={closeModal}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              className="h-6 w-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                          <h2 className="text-gray-800 text-2xl mb-4">
                            Change Password
                          </h2>
                          <form onSubmit={formik.handleSubmit}>
                            <div className="mb-6">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="currentPassword"
                              >
                                Current Password
                              </label>
                              <input
                                type="text"
                                id="currentPassword"
                                name="currentPassword"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={formik.values.currentPassword}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="mb-6">
                              <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="newPassword"
                              >
                                New Password
                              </label>
                              <input
                                type="text"
                                id="newpPassword"
                                name="newpPassword"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                value={formik.values.newpPassword}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="mb-6">
                              <button
                                type="submit"
                                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                disabled={loading}
                              >
                                {loading ? (
                                  <svg
                                    className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                    viewBox="0 0 24 24"
                                  ></svg>
                                ) : (
                                  "Save"
                                )}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    ) : (
                      <div className="bg-gray-300 p-4 rounded-md">
                        <div className="text-black mb-4">Your Profile</div>
                        <ul>
                          <li className="mb-2">
                            <div
                              className="flex items-center justify-between px-4 py-2 bg-green-400 rounded-md shadow-md cursor-pointer hover:bg-green-500 transition duration-300"
                              onClick={openModal}
                            >
                              <span className="text-white">
                                Change Password
                              </span>
                            </div>
                          </li>

                          <li>
                            <div
                              className="flex items-center justify-between px-4 py-2 bg-indigo-400 rounded-md shadow-md cursor-pointer hover:bg-indigo-500 transition duration-300"
                              onClick={handleLogout}
                            >
                              <span className="text-white">Logout</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}
