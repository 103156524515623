import React, { useEffect, useState } from "react";
import { FaFileSignature, FaSave } from "react-icons/fa";
import HeaderTopBar from "../../HeaderTopBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";

const EnrollmentOfficerUsernamePass = () => {
  const [loading, setLoading] = useState();
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/users/enrollment_officer`;
  const token = localStorage.getItem("token");
  const [regionList, setRegionList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);
  const [wardList, setWardList] = useState([]);

  const navigate = useNavigate();

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastName: "",
      userName: "",
      password: "",
      caregiverId: "",
      latitude: 0,
      address: "",
      occupation:"",
      maritalStatus:"",
      gender:"",
      dateOfBirth:"",
      otherNames:"",
      dateEnrolled:"",
      email:"",
      region:"",
      state:"",
      lga:"",
      ward:"",
      confirmPassword:"",
     
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("here");
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const allValue = {
      username: values?.username,
      firstname: values?.firstname,
      lastname: values?.lastName,
      password: values?.password,
      confirmPassword: values?.confirmPassword,
      email: values?.email,
      region: values?.region,
      state:values?.state,
      ward: values?.ward,
      lga: values?.lga,
      gender: values.gender || "",
      maritalStatus: values.maritalStatus || "",
      address:values?.address,
      otherNames:values?.otherNames,
      occupation:values?.occupation,
      geolocation:{
        latitude:values?.latitude
      }
    };
    if (values?.dateOfBirth !== undefined && values?.dateOfBirth !== "") {
      allValue.dateOfBirth = values.dateOfBirth;
    }
    
    if (values?.password !== values?.confirmPassword) {
      return toast.error("Passwords do not match");
    }
    if (values.email) {
      allValue.email = values.email;
    } else {
      delete allValue.email;
    }

    if (!values.username) {
      return toast.error("User Name is required");
    } else if (!values?.password) {
      return toast.error("Password is required");
    } else if (!values?.firstname) {
      return toast.error("First Name is required");
    } else if (!values?.email) {
      return toast.error("Email is required");
    } else if (!values?.lastName) {
      return toast.error("Last Name is required");
    }
    setLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/users/register`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.post(apiUrl, allValue, config);
      if (response.status === 201) {
        setLoading(false);
        toast.success("Saved Sucessfully");
        navigate("/emrollment/officer");
      }
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error);
    }
  };

  console.log("Formik-values",formik.values);

  const regionUrl = `${process.env.REACT_APP_MAIN_URL}/users/region/`;
  const stateUrl = `${process.env.REACT_APP_MAIN_URL}/users/states/`;
  const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/users/lga/`;
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/users/ward/`;
  const getRegion = () => {
    setLoading(true);
    axios
      .get(regionUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setRegionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in Region");
        setLoading(false);
      });
  };
  //Get State data
  const getState = () => {
    setLoading(true);
    axios
      .get(stateUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setStateList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in State");
        setLoading(false);
      });
  };
  //Get LGA data
  const getLga = () => {
    setLoading(true);
    axios
      .get(lgaUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setLgaList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };

  //Get Ward
  const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegion();
    getState();
    getLga();
    getWard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputConfigs = [
    {
      label: "First Name",
      id: "firstname",
      name: "firstname",
      type: "text",
      placeholder: "First Name",
      required: true,
    },
    {
      label: "Last Name",
      id: "lastName",
      name: "lastName",
      type: "text",
      placeholder: "Enter Last Name",
      required: true,
    },
    {
      label: "Email",
      id: "email",
      name: "email",
      type: "text",
      placeholder: "Email",
      required: true,
    },
    {
      label: "Geolocation",
      id: "latitude",
      name: "latitude",
      type: "number",
      placeholder: "GeoLocation",
      pattern: "[0-9]*",
      required: true,
    },

    {
      label: "Address",
      id: "address",
      name: "address",
      type: "text",
      placeholder: "Address",
      required: true,
    },
    {
      label: "Date Enrolled",
      id: "dateEnrolled",
      name: "dateEnrolled",
      type: "date",
      placeholder: "Enrolled Date",
    },

    {
      label: "Others Name",
      id: "otherNames",
      name: "otherNames",
      type: "text",
      placeholder: "Other Names",
    },

    {
      label: "Date of Birth",
      id: "dateOfBirth",
      name: "dateOfBirth",
      type: "date",
      placeholder: "Date of Birth",
    },

    {
      label: "Gender",
      id: "gender",
      name: "gender",
      type: "select",
      options: ["Male", "Female", "Other"],
      placeholder: "Select Gender",
      required: true,
    },

    {
      label: "Marital Status",
      id: "maritalStatus",
      name: "maritalStatus",
      type: "select",
      options: ["Single", "Married"],
      placeholder: "Select Marital Status",
      required: true,
    },
    {
      label: "Occupation",
      id: "occupation",
      name: "occupation",
      type: "text",
      placeholder: "Occupation",
    },
  ];
  const inputConfigs1 = [
    {
      label: "Username",
      id: "username",
      name: "username",
      type: "text",
      placeholder: "User Name",
      required: true,
    },
    {
      label: "Password",
      id: "password",
      name: "password",
      type: "text",
      placeholder: "Password",
      required: true,
    },
    {
      label: "Confirm Password",
      id: "confirmPassword",
      name: "confirmPassword",
      type: "text",
      placeholder: "confirmPassword",
      required: true,
    },
  ];

  const handleResetClick = () => {
    formik.resetForm();
  };

  return (
    <div>
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Enrollment"}
        subtitle={"Enrollment Officer"}
      />
      {loading && <Loader />}

      <form className="pt-4" onSubmit={formik.handleSubmit}>
        <div className="p-6 bg-white rounded-lg shadow-md flex flex-col md:flex-row">
          <div className="lg:w-9/12 md:w-8/12 sm:w-full text-gray-700 text-xl font-bold flex items-start sm:items-center">
            ADD NEW ENROLLMENT OFFICER
          </div>
          <div className="lg:w-1/12 md:w-1/12 sm:w-full flex items-center sm:items-center sm:pl-4">
            <div className="flex">
              <button
                type="button"
                className="bg-[#E99D00] text-white font-base rounded-lg px-6 py-2 w-full sm:w-auto flex items-center justify-center mx-1"
                onClick={handleResetClick}
              >
                Reset
              </button>

              <button
                className={`bg-green-600 hover-bg-green-900 text-white py-2 px-4 w-full sm:w-auto rounded-lg flex items-center justify-center mx-1 ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <div className="flex items-center">
                    <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                    <span>Loading...</span>
                  </div>
                ) : (
                  <>
                    <FaSave className="mr-2" />
                    Save
                  </>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className="w-full bg-white mt-2">
          <section className="container px-4 mx-auto">
            <div className="flex flex-col">
              {/* Filter start */}
              <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-3 gap-4 p-6 bg-white rounded-lg shadow-md">
                {inputConfigs.map((config, index) => (
                  <div
                    key={index}
                    className={`lg:col-span-1 md:col-span-1 sm:col-span-1 flex flex-col mb-4 ${
                      config.required ? "required-border" : ""
                    }`}
                  >
                    <label className="text-sm font-semibold mb-1">
                      {config.label}{config.required ? <span className="text-red-600">*</span> : null}:
                    </label>
                    {config.type === "select" ? (
                      <select
                        className={`w-full h-10 rounded border ${
                          "border-gray-300"
                        } focus:outline-none focus:border-blue-500 px-3`}
                        id={config.id}
                        name={config.name}
                        value={formik.values[config.name]}
                        onChange={formik.handleChange}
                      >
                        <option value="" disabled hidden>
                          {config.placeholder}
                        </option>
                        {config.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        className={`w-full h-10 rounded border ${
                          "border-gray-300"
                        } focus:outline-none focus:border-blue-500 px-3`}
                        type={config.type}
                        id={config.id}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formik.values[config.name]}
                        onChange={formik.handleChange}
                        pattern={config.pattern}
                      />
                    )}
                    {formik.touched[config.name] &&
                      formik.errors[config.name] && (
                        <div className="text-red-500 text-xs mt-1">
                          {formik.errors[config.name]}
                        </div>
                      )}
                  </div>
                ))}

                {/* END */}
                <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
                  <label className="text-sm font-semibold mb-1">
                      Region  <span className="text-red-600">*</span>:
                    </label>
                  <select
                    id="region"
                    name="region"
                    className="w-full border p-2 mb-4"
                    value={formik.values.region}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select a Region</option>
                    {regionList.map((region) => (
                      <option key={region.id} value={region.name}>
                        {region.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
                  <label className="text-sm font-semibold mb-1">
                      State:
                    </label>
                  <select
                    id="state"
                    name="state"
                    className="w-full border p-2 mb-4"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select a State</option>
                    {stateList.map((state) => (
                      <option key={state.id} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
                  <label className="text-sm font-semibold mb-1">
                      LGA:
                    </label>
                  <select
                    id="lga"
                    name="lga"
                    className="w-full border p-2 mb-4"
                    value={formik.values.lga}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select a LGA</option>
                    {lgaList.map((lga) => (
                      <option key={lga.id} value={lga.name}>
                        {lga.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
                  <label className="text-sm font-semibold mb-1">
                      Ward:
                    </label>
                  <select
                    id="ward"
                    name="ward"
                    className="w-full border p-2 mb-4"
                    value={formik.values.ward}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select a Ward</option>
                    {wardList.map((ward) => (
                      <option key={ward.id} value={ward.name}>
                        {ward.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
                  <div>Upload Image</div>
                  <input
                    type="file"
                    id="imageUpload"
                    name="imageUpload"
                    accept="image/*"
                  />
                </div>
                <div className="col-span-1 sm:col-span-1 mt-4 sm:mt-0">
                  <div className="border border-red-100 p-2 rounded-2xl bg-red-50">
                    N.B:
                    <span className="text-red-500 ml-2">
                      Red * field is must be required
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="pt-4">
          <div className="grid grid-cols-12 p-6 bg-white rounded-lg shadow-md">
            <div className="col-span-12 text-gray-700 text-xl font-bold">
              Enrollment officer username & password
            </div>
          </div>

          <div className="w-full bg-white mt-2">
            <section className="container px-4 mx-auto">
              <div className="flex flex-col">
                {/* Filter start */}
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-3 gap-4 p-6 bg-white rounded-lg shadow-md">
                  {inputConfigs1.map((config, index) => (
                    <div
                      key={index}
                      className={`lg:col-span-1 md:col-span-1 sm:col-span-3 flex flex-col mb-4 ${
                        config.required ? "required-border" : ""
                      }`}
                    >
                      <label className="text-sm font-semibold mb-1">
                      {config.label}{config.required ? <span className="text-red-600">*</span> : null}:
                      </label>
                      <input
                        className={`w-full h-10 rounded border ${
                           "border"
                        } focus:outline-none focus:border-blue-500 px-3`}
                        type={config.type}
                        id={config.id}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formik.values[config.name]}
                        onChange={formik.handleChange}
                      />
                      {formik.touched[config.name] &&
                        formik.errors[config.name] && (
                          <div className="text-red-500 text-xs mt-1">
                            {formik.errors[config.name]}
                          </div>
                        )}
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EnrollmentOfficerUsernamePass;
