import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_MAIN_URL}/auth/login`, values);
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        toast.success('Login Successful');
        window.location.href = '/';
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error('Invalid username or password');
      } else {
        toast.error('Login Failed'); 
      }
      setLoading(false);
    }
  };
  

  return (
    <div className="bg-gray-300 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-96">
        <h1 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Login
        </h1>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                User Name
              </label>
              <Field
                type="text"
                id="username"
                name="username"
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Your username"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                className="w-full px-3 py-2 border rounded-md"
                placeholder="Your password"
              />
            </div>
            <div className="flex justify-between items-center">
              <button
                type="submit"
                className={`${
                  loading ? "opacity-75 pointer-events-none" : ""
                } bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline relative`}
                disabled={loading}
              >
                Login
                {loading && (
                  <span className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                    <svg
                      className="animate-spin h-5 w-5 mr-3 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
}
