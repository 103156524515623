import React, { useEffect, useState } from "react";
import { FaFileSignature } from "react-icons/fa";
import HeaderTopBar from "../../HeaderTopBar";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BiDetail } from "react-icons/bi";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";
import { IoIosAdd } from "react-icons/io";

const EnrollmentOfficer = () => {
  const [loading, setLoading] = useState();
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/users/enrollment_officer`;
  const token = localStorage.getItem("token");
  const [officerList, setOfficerList] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [lgaList, setLgaList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [filterDataList, setFilterDataList] = useState([]);

  const navigate = useNavigate();
  const handleClick = (id) => {
    const detailLink = `/officer-detail?id=${id}`;
    navigate(detailLink);
  };
  const [deleteUrl, setDeleteUrl] = useState(
    `${process.env.REACT_APP_MAIN_URL}/users/enrollment_officer`
  );

  const searchFormik = useFormik({
    initialValues: {
      lga: 0,
      ward: 0,
      enrollmentId: "",
      dateOfBirth: "",
      dateEnrolled: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setIsFilter(true);
      const filteredData = officerList.filter((officer) => {
        return (
          (values.enrollmentId === 0 ||
            officer.enrollmentId === Number(values.enrollmentId)) &&
          (values.dateOfBirth === "" ||
            officer.dateOfBirth === values.dateOfBirth) &&
          (values.dateEnrolled === "" ||
            officer.dateEnrolled === values.dateEnrolled) &&
          (values.lga === 0 || officer.lga === values.lga) &&
          (values.ward === 0 || officer.ward === values.ward)
        );
      });
      setFilterDataList(filteredData);
    },
  });

  const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/users/lga/`;
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/users/ward/`;
  const getLga = () => {
    setLoading(true);
    axios
      .get(lgaUrl, axiosConfig)
      .then((response) => {
        setLgaList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };
  //Get Ward
  const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };

  useEffect(() => {
    getLga();
    getWard();
  }, []);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const fetchData = () => {
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setOfficerList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Delete function
  const handleDeleteClick = () => {
    const selectedIDs = selectedData.map((row) => row.enrollmentId);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(deleteUrl, { user_id: selectedIDs }, config)
      .then((response) => {
        toast.success("Deleted successfully");
        setSelectedData([]);
        fetchData();
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (searchTerm == "") {
      fetchData();
    }
  }, [searchTerm]);
 

  const handleSearchClick = (e) => {
    setLoading(true);
    setSearchTerm(e.target.value);
    const filteredOfficers = officerList.filter((officer) =>
      ['firstname', 'lastname', 'username', 'dateOfBirth', 'ward', 'lga'].some((key) => {
        const value = officer[key];
        if (typeof value === "string" || typeof value === "number") {
          const lowerCaseSearchTerm = e.target.value.toLowerCase();
          const lowerCaseValue = String(value).toLowerCase();
          return lowerCaseValue.startsWith(lowerCaseSearchTerm);
        }
        return false;
      })
    );
    setOfficerList(filteredOfficers);
    setLoading(false);
  };
  

  // Define your change handler for the search input
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handler for when the table header checkbox is clicked
  const handleTableHeaderCheckboxClick = () => {
    if (selectedData?.length === officerList?.length) {
      setSelectedData([]);
    } else {
      setSelectedData(officerList);
    }
  };

  //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const tableData = isFilter ? filterDataList : officerList;
  const paginatedData = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handler for when an individual checkbox is clicked
  const handleRowCheckboxClick = (rowData) => {
    if (selectedData.includes(rowData)) {
      setSelectedData(
        selectedData.filter((selectedRow) => selectedRow !== rowData)
      );
    } else {
      setSelectedData([...selectedData, rowData]);
    }
  };

  const handleNewButtonClick = () => {
    setShowModal(true);
  };
  const handleNewModalClose = () => {
    setShowModal(false);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userName: "",
      password: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      // Handle form submission, e.g., save the new entry
      // You can add your logic here
      // Close the modal after submission
      handleNewModalClose();
    },
  });

  const modalOverlayClass = showModal
    ? "fixed inset-0 flex items-center justify-center z-50 bg-opacity-75 backdrop-blur-sm"
    : "";

  const navigateHere = useNavigate();
  const enrollmentUsernameAndPassword = (id) => {
    const detailLink = `/enrollment-username-and-password`;
    navigateHere(detailLink);
  };

  return (
    <div>
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Enrollment"}
        subtitle={"Enrollment Officer"}
      />
      {loading && <Loader />}

      <form onSubmit={searchFormik.handleSubmit}>
        <div className="w-full mt-2">
          <section className="container px-4 mx-auto">
            <div className="flex flex-col">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6 bg-white rounded-lg shadow-md lg:grid-cols-3">
                <div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Enrollment ID:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="text"
                        id="enrollmentId"
                        name="enrollmentId"
                        placeholder="Enter ID"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.enrollmentId}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Date of Birth:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="date"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        placeholder="Enter date of Birth"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.dateOfBirth}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className=" text-gray-700 col-span-1 flex items-center text-sm">
                      Date enrolled:
                    </label>
                    <div className="col-span-3">
                      <input
                        type="date"
                        id="dateEnrolled"
                        name="dateEnrolled"
                        placeholder="Enter date"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.dateEnrolled}
                        // placeholder={placeholder}
                        className="w-full border rounded-lg p-2 focus:outline-none focus:ring focus:border-blue-500"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className="text-gray-700 col-span-1 flex items-center text-sm">
                      LGA
                    </label>
                    <div className="col-span-3">
                      <select
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400"
                        id="lga"
                        name="lga"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.lga?.id}
                      >
                        <option value="">Select a LGA</option>
                        {lgaList.map((lga) => (
                          <option key={lga.id} value={lga.id}>
                            {lga.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <label className="text-gray-700 col-span-1 flex items-center text-sm">
                      Ward
                    </label>
                    <div className="col-span-3">
                      <select
                        className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-500 bg-gray-100 hover:border-gray-400"
                        id="ward"
                        name="ward"
                        onChange={searchFormik.handleChange}
                        value={searchFormik.values.ward?.id}
                      >
                        <option value="">Select a Ward</option>
                        {wardList.map((lga) => (
                          <option key={lga.id} value={lga.id}>
                            {lga.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className=" justify-end mt-1">
                  <button
                    className=" bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                    type="submit"
                  >
                    Filter
                  </button>
                </div>
              </div>
              {/* </form> */}

              {/* Filter end */}

              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                    <div className="flex justify-between mb-1 p-2">
                      <button
                        type="button"
                        onClick={handleDeleteClick}
                        className="bg-red-500 text-white rounded px-4 py-2"
                      >
                        Delete
                      </button>
                      <div className="flex">
                        <button
                          onClick={enrollmentUsernameAndPassword}
                          type="button"
                          className="bg-green-500 text-white rounded-l px-4 py-2 flex items-center mr-2" 
                        >
                          <IoIosAdd size={20} className="mr-2" /> New
                        </button>

                        <input
                          type="text"
                          value={searchTerm}
                          onChange={handleSearchClick}
                          placeholder="Search..."
                          className="border border-gray-300 dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700 px-4 py-2"
                        />
                        {/* <button
                          type="button"
                          onClick={handleSearchClick}
                          className="bg-blue-500 text-white rounded-r px-4 py-2"
                        >
                          Search
                        </button> */}
                      </div>
                    </div>

                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                      <thead className="bg-gray-50 dark:bg-gray-800">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            <div className="flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                onChange={handleTableHeaderCheckboxClick}
                                checked={
                                  selectedData?.length === officerList?.length
                                }
                              />

                              <button className="flex items-center gap-x-2">
                                <span>Details</span>

                                {/*  */}
                              </button>
                            </div>
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            First Name
                          </th>

                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Last Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            User Name
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Date of Birth
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            Ward
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                          >
                            LGA
                          </th>

                          <th scope="col" className="relative py-3.5 px-4">
                            <span className="sr-only">Actions</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {paginatedData?.map((val, idx) => (
                          <tr key={idx}>
                            <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                              <div className="inline-flex items-center gap-x-3">
                                <input
                                  type="checkbox"
                                  className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                  onChange={() => handleRowCheckboxClick(val)}
                                  checked={selectedData.includes(val)}
                                />
                                <span
                                  onClick={() => {
                                    handleClick(val?.enrollmentId);
                                  }}
                                  className="cursor-pointer text-blue-500 hover:underline"
                                >
                                  <BiDetail />
                                </span>
                              </div>
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                              {val?.firstname}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.lastname}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.username}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.dateOfBirth}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                              {val?.ward}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap ">
                              {val?.lga}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            {paginatedData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    className={`${
                      currentPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))}

                {currentPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </section>
        </div>
      </form>
      <div className={modalOverlayClass}>
        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 bg-opacity-75 backdrop-blur-lg">
            <div className="w-full max-w-md p-6 mx-4 bg-white rounded-lg shadow-md">
              <h2 className="text-2xl font-bold text-blue-600 mb-4">
                Add New User
              </h2>
              <form onSubmit={formik.handleSubmit}>
                <div className="mb-4">
                  <label
                    htmlFor="firstName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="lastName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="userName"
                    className="text-gray-700 font-medium mb-2"
                  >
                    User Name
                  </label>
                  <input
                    type="text"
                    id="userName"
                    name="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.userName}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="text-gray-700 font-medium mb-2"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring focus:border-blue-500"
                  />
                </div>
                <div className="mt-6">
                  <button
                    type="submit"
                    className="bg-blue-500 text-white rounded px-4 py-2 hover:bg-blue-600 focus:outline-none"
                  >
                    Save
                  </button>
                  <button
                    onClick={handleNewModalClose}
                    className="bg-gray-300 text-gray-700 rounded px-4 py-2 ml-4 hover:bg-gray-400 focus:outline-none"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnrollmentOfficer;
