import axios from "axios";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaEdit, FaSave } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";
import { toast } from "react-toastify";

export default function CaregiverMain() {
  const [careGiverList, setCaregiverList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = localStorage.getItem("token");

  //Form handler start
  const updateInitialValue = {
    careGiverId: careGiverList?.careGiverId || 0,
    firstname: careGiverList?.firstname || "",
    lastname: careGiverList?.lastname || "",
    otherNames: careGiverList?.otherNames || "",
    dateOfBirth: careGiverList?.dateOfBirth || "",
    gender: careGiverList?.gender || "",
    maritalStatus: careGiverList?.maritalStatus || "",
    phone: careGiverList?.phone || "",
    email: careGiverList?.email || "",
    address: careGiverList?.address || "",
    region: careGiverList?.region || "",
    state: careGiverList?.state || "",
    lga: careGiverList?.lga || "",
    ward: careGiverList?.ward || "",
    occupation: careGiverList?.occupation || "",
    photoDate: careGiverList?.photoDate || "",
    enrolledDate: careGiverList?.enrolledDate || "",
    careGiverFrontId: careGiverList?.careGiverFrontId || 0,
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  const handleSubmit = async (values) => {
    const allValue = {
      ...values,
    };
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/caregiver/${id}`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 200) {
        setBtnLoading(false);
        toast.success("Saved Sucessfully");
        console.log("Response:", response.data);
        if (id) {
          fetchData(id);
        }
      }
    }catch (error) {
      setBtnLoading(false);
      console.error("Error:", error);
    }
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/caregiver/${id}`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setCaregiverList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong")
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const inputConfigs = [
    {
      label: "Enrollment ID",
      id: "careGiverFrontId",
      name: "careGiverFrontId",
      type: "text",
      placeholder: "Enter Enrollment ID",
    },
    {
      label: "Phone",
      id: "phone",
      name: "phone",
      type: "text",
      placeholder: "Enter Phone",
    },
    {
      label: "Phone date",
      id: "phoneDate",
      name: "phoneDate",
      type: "date",
      placeholder: "Enter Phone Date",
    },
    {
      label: "Caregiver ID",
      id: "careGiverId",
      name: "careGiverId",
      type: "text",
      placeholder: "Caregiver ID",
    },
    {
      label: "Email",
      id: "email",
      name: "email",
      type: "text",
      placeholder: "Email",
    },
    {
      label: "Geolocation",
      id: "latitude",
      name: "latitude",
      type: "text",
      placeholder: "GeoLocation",
    },
    {
      label: "First Name",
      id: "firstname",
      name: "firstname",
      type: "text",
      placeholder: "First Name",
    },
    {
      label: "Address",
      id: "address",
      name: "address",
      type: "text",
      placeholder: "Address",
    },
    {
      label: "Date Enrolled",
      id: "enrolledDate",
      name: "enrolledDate",
      type: "date",
      placeholder: "Enrolled Date",
    },
    {
      label: "Last Name",
      id: "lastname",
      name: "lastname",
      type: "text",
      placeholder: "Last Name",
    },
    {
      label: "Region",
      id: "region",
      name: "region",
      type: "text",
      placeholder: "Region",
    },
    {
      label: "Others Name",
      id: "otherNames",
      name: "otherNames",
      type: "text",
      placeholder: "Other Names",
    },
    {
      label: "State",
      id: "state",
      name: "state",
      type: "text",
      placeholder: "State",
    },
    {
      label: "Date of Birth",
      id: "dateOfBirth",
      name: "dateOfBirth",
      type: "date",
      placeholder: "Date of Birth",
    },
    { label: "LGA", id: "lga", name: "lga", type: "text", placeholder: "LGA" },
    {
      label: "Gender",
      id: "gender",
      name: "gender",
      type: "text",
      placeholder: "Gender",
    },
    {
      label: "Ward",
      id: "ward",
      name: "ward",
      type: "text",
      placeholder: "Ward",
    },
    {
      label: "Marital Status",
      id: "maritalStatus",
      name: "maritalStatus",
      type: "text",
      placeholder: "Marital Status",
    },
    {
      label: "Health Facility Name",
      id: "health_facility",
      name: "health_facility",
      type: "text",
      placeholder: "Health Facility",
    },
    {
      label: "Occupation",
      id: "occupation",
      name: "occupation",
      type: "text",
      placeholder: "Occupation",
    },
  ];

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="text-center mt-12">
      {loading && <Loader />}
      <div className="mt-16 w-full bg-white rounded-2xl flex items-center text-gray-700">
        <Link
          to={`/caregiver?id=${id}`}
          className={`w-1/2 text-center py-2 cursor-pointer rounded-tl-2xl rounded-bl-2xl transition duration-300 ${
            location.pathname === "/caregiver"
              ? "bg-blue-500 text-white font-semibold hover:shadow-md shadow-md"
              : "hover:bg-gray-100 text-sm hover:shadow-md font-semibold shadow-md"
          }`}
        >
          Caregiver
        </Link>
        <Link
          to={`/child?id=${id}`}
          className={`w-1/2 text-center py-2 cursor-pointer rounded-tr-2xl rounded-br-2xl transition duration-300 ${
            location.pathname === `/child`
              ? "bg-blue-500 text-white font-semibold hover:shadow-md shadow-md"
              : "hover:bg-gray-100 text-sm hover:shadow-md font-semibold shadow-md"
          }`}
        >
          Child
        </Link>
      </div>
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-4">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 items-center">
              <div className="col-span-1">
                <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                  {careGiverList?.photoUrl ? (
                    <img
                      src={careGiverList?.photoUrl}
                      alt="User"
                      className="w-32 h-32 rounded-full"
                    />
                  ) : (
                    <AiOutlineUser size={64} color="white" />
                  )}
                </div>
              </div>
              <div className="col-span-1 md:col-span-1 lg:col-span-2 xl:col-span-2">
                <div className="grid grid-cols-1 gap-4 text-xs">
                  {inputConfigs.map((config, index) => (
                    <div
                      key={index}
                      className="col-span-1 flex items-center min-w-[50px]"
                    >
                      <label>{config.label}:</label>
                      <input
                        className="ml-2 h-8"
                        type={config.type}
                        id={config.id}
                        name={config.name}
                        placeholder={config.placeholder}
                        value={formik.values[config.name]}
                        onChange={formik.handleChange}
                        disabled={!isEditing}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 space-x-0 md:space-x-4 p-3">
            <button
              className="w-full md:w-auto bg-blue-600 hover:bg-blue-900 text-white py-2 px-4 rounded-lg flex items-center"
              type="button"
              onClick={handleEditClick}
            >
              <FaEdit className="mr-2" /> Edit
            </button>
            <button
              className={`w-full md:w-auto bg-green-600 hover:bg-green-900 text-white py-2 px-4 rounded-lg flex items-center ${
                btnLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              type="submit"
              onClick={handleSubmit}
              disabled={btnLoading}
            >
              {btnLoading ? (
                <div className="flex items-center">
                  <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                  <span>Loading...</span>
                </div>
              ) : (
                <>
                  <FaSave className="mr-2" />
                  Save
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
