import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiDetail } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader/Loader";
import HeaderTopBar from "./HeaderTopBar";
import { FaFileSignature } from "react-icons/fa";
import { useFormik } from "formik";

function Reminder() {
  const [loading, setLoading] = useState();
  const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/reminders`;
  const token = localStorage.getItem("token");
  const [reminderList, setReminderList] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [filteredDataList, setFilteredDataList] = useState([]);
  const navigate = useNavigate();
  const handleClick = (val) => {
    const detailLink = `/reminder-details?caregiverId=${val?.caregiver?.careGiverId}&childID=${val?.child?.childId}&id=${val?.id}`;
    navigate(detailLink);
  };
  const [deleteUrl, setDeleteUrl] = useState(
    `${process.env.REACT_APP_MAIN_URL}/caregivers/reminders`
  );
  console.log("reminderList", reminderList);

  const searchFormik = useFormik({
    initialValues: {
      caregiverId: "",
      childId: "",
      totalVc: "",
      totalVcDate: "",
      nextVcDate: "",
      vcGiven: "",
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      console.log("Values", values);
      setIsFilter(true);
      const filteredData = reminderList.filter((officer) => {
        console.log("officer", officer);
        return (
          values.caregiverId === 0 ||
          officer.caregiver?.careGiverId === values.caregiverId ||
          values.childId === 0 ||
          officer.child?.childId === Number(values.childId) ||
          values.totalVc === 0 ||
          officer.child?.total_vaccination === Number(values.totalVc) ||
          officer.child?.last_vaccination_date === values.totalVcDate ||
          officer.child?.vaccination_given === Number(values.vcGiven)
        );
      });
      setFilteredDataList(filteredData);
      // setOfficerList(filteredData);

      console.log("Filtered Data", filteredData);
    },
  });

  console.log("Formik.values", searchFormik.values);

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const fetchData = () => {
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        console.log("Data:", response.data);
        setReminderList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Something went wrong");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  //Delete function
  const handleDeleteClick = () => {
    const selectedIDs = selectedData.map((row) => row.id);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .post(deleteUrl, { reminder_id: selectedIDs }, config)
      .then((response) => {
        console.log("Successfully deleted data:", response.data);
        toast.success("Deleted successfully");
        setSelectedData([]);
        fetchData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    if (searchTerm == "") {
      fetchData();
    }
  }, [searchTerm]);

  const handleSearchClick = (e) => {
    setLoading(true);
    setSearchTerm(e.target.value);
    const filteredReminders = reminderList.filter((reminder) =>
      [
        "careGiverId",
        "childId",
        "vaccinationName",
        "last_vaccination_date",
        "total_vaccination",
        "vaccination_given",
      ].some((key) => {
        const value = reminder[key];
        if (typeof value === "string" || typeof value === "number") {
          const lowerCaseSearchTerm = e.target.value.toLowerCase();
          const lowerCaseValue = String(value).toLowerCase();
          return lowerCaseValue.startsWith(lowerCaseSearchTerm);
        }
        return false;
      })
    );
    setReminderList(filteredReminders);
    setLoading(false);
  };

  // Define your change handler for the search input
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handler for when the table header checkbox is clicked
  const handleTableHeaderCheckboxClick = () => {
    if (selectedData?.length === reminderList?.length) {
      setSelectedData([]);
    } else {
      setSelectedData(reminderList);
    }
  };

  //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const tableData = isFilter ? filteredDataList : reminderList;
  const paginatedData = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const totalPages = Math.ceil(tableData?.length / itemsPerPage);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // Handler for when an individual checkbox is clicked
  const handleRowCheckboxClick = (rowData) => {
    if (selectedData.includes(rowData)) {
      setSelectedData(
        selectedData.filter((selectedRow) => selectedRow !== rowData)
      );
    } else {
      setSelectedData([...selectedData, rowData]);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Enrollment"}
        subtitle={"reminder"}
      />
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <form onSubmit={searchFormik.handleSubmit}>
        <section className="container px-4 mx-auto">
          <div className="flex flex-col">
            {/* Filter start */}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-4 bg-white p-2 rounded-sm">
              <div>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label
                    for="caregiver-id"
                    className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4"
                  >
                    Caregiver ID:
                  </label>
                  <input
                    type="text"
                    id="caregiverId"
                    name="caregiverId"
                    placeholder="Enter ID"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values?.caregiverId}
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label
                    for="child-id"
                    className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4"
                  >
                    Child ID:
                  </label>

                  <input
                    type="text"
                    id="childId"
                    name="childId"
                    placeholder="Enter ID"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values?.childId}
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>
              </div>

              <div>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label
                    for="vc-given"
                    className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4"
                  >
                    VC Given:
                  </label>
                  <input
                    type="text"
                    id="vcGiven"
                    name="vcGiven"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values?.vcGiven}
                    placeholder="Enter VC Given"
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-4">
                  <label
                    for="total-vc"
                    className="text-sm leading-7 text-blueGray-500 md:col-span-1 mt-4"
                  >
                    Total VC:
                  </label>
                  <input
                    type="text"
                    id="totalVc"
                    name="totalVc"
                    onChange={searchFormik.handleChange}
                    value={searchFormik.values?.totalVc}
                    placeholder="Enter total VC"
                    className="md:col-span-3 w-full px-4 py-2 mt-2 text-sm text-black rounded-lg bg-gray-100 focus:border-blueGray-500 focus:bg-white focus:outline-none focus:shadow-outline focus:ring-2 ring-offset-current ring-offset-2"
                  />
                </div>
              </div>

              <div className=" justify-end mt-1">
                <button
                  className=" bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                  type="submit"
                >
                  Filter
                </button>
              </div>
            </div>

            {/* Filter end */}

            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                  <div className="flex justify-between mb-1 p-2">
                    <button
                      type="button"
                      onClick={handleDeleteClick}
                      className="bg-red-500 text-white rounded px-4 py-2"
                    >
                      Delete
                    </button>
                    <div className="flex">
                      <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchClick}
                        placeholder="Search..."
                        className="rounded-l border border-r-0 border-gray-300 dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700 px-4 py-2"
                      />
                      {/* <button
                      type="button"
                      onClick={handleSearchClick}
                      className="bg-blue-500 text-white rounded-r px-4 py-2"
                    >
                      Search
                    </button> */}
                    </div>
                  </div>
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-gray-50 dark:bg-gray-800">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          <div className="flex items-center gap-x-3">
                            <input
                              type="checkbox"
                              className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                              onChange={handleTableHeaderCheckboxClick}
                              checked={
                                selectedData?.length === reminderList?.length
                              }
                            />

                            <button className="flex items-center gap-x-2">
                              <span>Details</span>
                            </button>
                          </div>
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Caregiver ID
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Child ID
                        </th>

                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Last Vaccination Name
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Last Vaccination Date
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Total Vaccination
                        </th>
                        <th
                          scope="col"
                          className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                        >
                          Vaccination Given
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                      {paginatedData?.map((val, idx) => (
                        <tr key={idx}>
                          <td className="px-4 py-4 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">
                            <div className="inline-flex items-center gap-x-3">
                              <input
                                type="checkbox"
                                className="text-blue-500 border-gray-300 rounded dark:bg-gray-900 dark:ring-offset-gray-900 dark:border-gray-700"
                                onChange={() => handleRowCheckboxClick(val)}
                                checked={selectedData.includes(val)}
                              />
                              <span
                                onClick={() => {
                                  handleClick(val);
                                }}
                                className="cursor-pointer text-blue-500 hover:underline"
                              >
                                <BiDetail />
                              </span>
                            </div>
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                            {val?.caregiver?.careGiverId}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                            {val?.child?.childId}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                            {val?.vaccinationName}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                            {val?.child?.last_vaccination_date}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                            {val?.child?.total_vaccination}
                          </td>
                          <td className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap">
                            {val?.child?.vaccination_given}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {paginatedData?.length > 0 ? (
            <div className="flex items-center justify-center mt-8 space-x-4">
              {currentPage > 1 && (
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                >
                  Previous
                </button>
              )}

              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  type="button"
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`${
                    currentPage === index + 1
                      ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                      : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                  } focus:outline-none focus:ring focus:border-pink-300`}
                >
                  {index + 1}
                </button>
              ))}

              {currentPage < 1 ? (
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled
                  className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                >
                  Next
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                >
                  Next
                </button>
              )}
            </div>
          ) : (
            <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
              No Data Found
            </div>
          )}
        </section>
      </form>
    </div>
  );
}

export default Reminder;
