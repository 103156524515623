import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; // Import the necessary components from react-router-dom
import Layout from "./components/shared/Layout";
import Dashboard from "./components/Dashboard";
import MainDashboard from "./components/MainDashboard";
import Reminder from "./components/Reminder";
import CashTransfer from "./components/CashTransfer";
import Caregiver from "./components/pages/enrollment/Caregiver";
import EnrollmentOfficer from "./components/pages/enrollment/EnrollmentOfficer";
import CaregiverDetail from "./components/pages/caregiver/CaregiverDetail";
import CaregiverMain from "./components/pages/caregiver/CaregiverMain";
import CaregiverChild from "./components/pages/caregiver/CaregiverChild";
import Login from "./components/pages/auth/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ChildHistory from "./components/pages/caregiver/ChildHistory";
import OfficerDetail from "./components/pages/caregiver/OfficerDetail";
import AddTransaction from "./components/pages/caregiver/AddTransaction";
import ReminderDetails from "./components/pages/caregiver/ReminderDetails";
import axios from "axios";
import EnrollmentOfficerUsernamePass from "./components/pages/enrollment/EnrollmentOfficerUsernamePass";
import Addministration from "./components/Administration";

// Define your redirectIfUser and logoutUser functions if they are not already defined

function App() {
  const token = localStorage.getItem("token");

  // useEffect(() => {
  //   axios.interceptors.response.use(
  //     function (res) {
  //       return res;
  //     },
  //     (error) => {
  //       if (error?.response?.status === 401) {
  //         window.location.href = "/login"; // Use window.location.href to navigate to the login page
  //         localStorage.clear(); // Clear the expired token from local storage
  //         return Promise.reject(error);
  //       } else {
  //         return Promise.reject(error);
  //       }
  //     }
  //   );
  // }, []);

  return (
    <Router>
      <Routes>
      <Route path="/login" element={<Login />} />
        {token ? (
          <Route path="/" element={<Layout />}>
            <Route index element={<Dashboard />} />
            <Route path="/transfer" element={<CashTransfer />} />
            <Route path="/reminder" element={<Reminder />} />
            <Route path="/emrollment/caregiver" element={<Caregiver />} />
            <Route path="/emrollment/officer" element={<EnrollmentOfficer />} />
            <Route path="/caregiver-detail" element={<CaregiverDetail />} />
            <Route path="/caregiver" element={<CaregiverMain />} />
            <Route path="/officer-detail" element={<OfficerDetail />} />
            <Route path="/child" element={<CaregiverChild />} />
            <Route path="/child/history" element={<ChildHistory />} />
            <Route path="/add-transaction" element={<AddTransaction />} />
            <Route path="/reminder-details" element={<ReminderDetails />} />
            <Route path="/administration" element={<Addministration />} />
            <Route path="/enrollment-username-and-password" element={<EnrollmentOfficerUsernamePass />} />
          </Route>
        ) : (
          <Route path="/" element={<Navigate to="/login" />} />
        )}
       
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} />
    </Router>
  );
}

export default App;
