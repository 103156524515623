import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaEdit, FaSave } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function ReminderDetails() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const caregiverID = searchParams.get("caregiverId");
  const childID = searchParams.get("childID");
  const [isEditing, setIsEditing] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [caregiverDetail, setCaregiverDetail] = useState(null);
  const navigate = useNavigate();

  const updateInitialValue = {
    vaccinationName: "",
    vaccinationNumber: "",
    lastDate: "",
    nextDueDateStart: "",
    type: "",
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });
  const handleSubmit = async (values) => {
    
    const allValue = {
      ...values,
      lastDate: values?.lastDate ? new Date(values.lastDate) : null,
  nextDueDateStart: values?.nextDueDateStart ? new Date(values.nextDueDateStart) : null,
      child: caregiverDetail?.child,
    };
    if (allValue.lastDate instanceof Date && !isNaN(allValue.lastDate.getTime())) {
      const lastDateYear = allValue.lastDate.getFullYear();
      const lastDateMonth = String(allValue.lastDate.getMonth() + 1).padStart(2, '0');
      const lastDateDay = String(allValue.lastDate.getDate()).padStart(2, '0');
      allValue.lastDate = `${lastDateYear}-${lastDateMonth}-${lastDateDay}`;
    }
    
    if (allValue.nextDueDateStart instanceof Date && !isNaN(allValue.nextDueDateStart.getTime())) {
      const nextDueDateYear = allValue.nextDueDateStart.getFullYear();
      const nextDueDateMonth = String(allValue.nextDueDateStart.getMonth() + 1).padStart(2, '0');
      const nextDueDateDay = String(allValue.nextDueDateStart.getDate()).padStart(2, '0');
      allValue.nextDueDateStart = `${nextDueDateYear}-${nextDueDateMonth}-${nextDueDateDay}`;
    }
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/${caregiverID}/childs/${childID}/remainders/${id}/`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 200) {
        setBtnLoading(false);
        toast.success("Saved Sucessfully");
        formik.resetForm();
        const detailLink = `/child?id=${caregiverID}`;
        navigate(detailLink);
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error("Something went wrong");
    }
  };
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/${caregiverID}/childs/${childID}/remainders/${id}/`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setCaregiverDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const inputConfigs = [
    {
      label: "Child ID",
      id: "childID",
      name: "childID",
      type: "text",
      placeholder: "Enter Child ID",
    },
    {
      label: "Name",
      id: "vaccinationName",
      name: "vaccinationName",
      type: "text",
      placeholder: "Enter Name",
    },
    {
      label: "Vaccination Number",
      id: "vaccinationNumber",
      name: "vaccinationNumber",
      type: "text",
      placeholder: "Vaccination Number",
    },
    {
      label: "Last date",
      id: "lastDate",
      name: "lastDate",
      type: "date",
      placeholder: "Enter Last Date",
    },
    {
      label: "Next date",
      id: "nextDueDateStart",
      name: "nextDueDateStart",
      type: "date",
      placeholder: "Next Date",
    },
  ];
  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };
  return (
    <div className="mt-16">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-5">
        <div className="p-6">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-1">
              <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                <AiOutlineUser size={64} color="white" />
              </div>
            </div>

            <div className="col-span-3">
              <div className="text-gray-600 dark:text-gray-400 text-sm">
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">
                    Caregiver ID
                  </span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.careGiverId}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">Name</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.firstname}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">Gender</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.gender}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">Phone</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.phone}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4 font-semibold">
                    Occupation
                  </span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.occupation}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4 font-semibold">State</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.state}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4 font-semibold">LGA</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.lga}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4 font-semibold">Ward</span>
                  <span className="w-3/4 text-sm">
                    :{caregiverDetail?.caregiver?.ward}
                  </span>
                </div>
                <div className="flex justify-between font-bold border-b border-gray-300 mt-5">
                  <span className="text-sm w-1/4 font-bold">
                    New Transaction
                  </span>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid grid-cols-1 gap-2 text-xs mt-2">
                    {inputConfigs.map((config, index) => (
                      <div
                        key={index}
                        className="col-span-1 flex items-center min-w-[50px]"
                      >
                        <label>{config.label}:</label>
                        <input
                          className="ml-2 h-8"
                          type={config.type}
                          id={config.id}
                          name={config.name}
                          placeholder={config.placeholder}
                          value={formik.values[config.name]}
                          onChange={formik.handleChange}
                          disabled={!isEditing}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="flex items-center space-x-4 p-3">
                    <button
                      className="bg-blue-600 hover:bg-blue-900 text-white py-2 px-4 rounded-lg flex items-center"
                      type="button"
                      onClick={handleEditClick}
                    >
                      <FaEdit className="mr-2" /> Edit
                    </button>
                    <button
                      className={`bg-green-600 hover:bg-green-900 text-white py-2 px-4 rounded-lg flex items-center ${
                        btnLoading ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      type="submit"
                      disabled={btnLoading}
                    >
                      {btnLoading ? (
                        <div className="flex items-center">
                          <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                          <span>Loading...</span>
                        </div>
                      ) : (
                        <>
                          <FaSave className="mr-2" />
                          Save
                        </>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>

            {/* </form> */}
          </div>
        </div>
      </div>
    </div>
  );
}
