import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";

export default function CaregiverDetail() {
  const [loading, setLoading] = useState(false);
  const [caregiverDetail, setCaregiverDetail] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const caregiverId = searchParams.get("caregiverId");
  const childID = searchParams.get("childID");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const handleAddNewClick = () => {
    navigate(
      `/add-transaction?caregiverId=${caregiverId}&childID=${childID}&id=${id}`
    );
  };

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/${caregiverId}/childs/${childID}/conditional-cashs/`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setCaregiverDetail(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="mt-16">
      <div className="max-w-2xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-4 items-center">
            <div className="sm:col-span-1">
              <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                <AiOutlineUser size={64} color="white" />
              </div>
            </div>
            <div className="sm:col-span-3">
              <div className="text-lg text-gray-600 dark:text-gray-400">
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Caregiver ID:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.careGiverId}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Name:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.firstname}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Gender:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.gender}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Phone:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.phone}
                  </span>
                </div>
                <div className="flex justify-between mb-2">
                  <span className="text-sm w-1/4">Occupation:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.occupation}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">State:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.state}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">LGA:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.lga}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm w-1/4">Ward:</span>
                  <span className="w-3/4 text-sm">
                    {caregiverDetail?.[0]?.caregiver?.ward}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between ml-3">
          <span className="text-xl font-semibold">Transaction history</span>
          <div className="flex items-center mr-2">
            <button
              className="ml-4 bg-blue-500 text-white px-4 py-2 rounded text-sm"
              onClick={handleAddNewClick}
            >
              Add New
            </button>
          </div>
        </div>
        <div className="ml-3">
          <select className="w-32 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring focus:border-blue-500">
            <option value="all">All</option>
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
          </select>
        </div>

        <div className="p-3">
          <div className="max-h-64 sm:max-h-full overflow-y-auto">
            <table className="min-w-full bg-white border border-gray-300 shadow-sm rounded-lg text-sm">
              <thead className="bg-gray-100">
                <tr>
                  <th className="py-2 px-4 text-left">Amount</th>
                  <th className="py-2 px-4 text-left">Eligible for CCT</th>
                  <th className="py-2 px-4 text-left">Date Pending/Received</th>
                  <th className="py-2 px-4 text-left">Status</th>
                </tr>
              </thead>
              <tbody>
                {caregiverDetail.map((item, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="py-2 px-4">{item.amount}</td>
                      <td
                        className={`py-2 px-4 ${
                          item.eligible_for_cct === "ELIGIBLE"
                            ? "text-green-600"
                            : "text-red-600"
                        }`}
                      >
                        {item.eligible_for_cct}
                      </td>
                      <td className="py-2 px-4">{item.nextDueDateStart}</td>
                      <td className="py-2 px-4">{item.status}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
