import React from 'react';
import { BounceLoader } from 'react-spinners';

const Loader = () => {
  return (
    <div className="loader-container flex justify-center items-center h-screen">
      <BounceLoader color="#3498db" size={60} />
    </div>
  );
};

export default Loader;
