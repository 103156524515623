import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaEdit, FaSave } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { CiTimer } from "react-icons/ci";
import { toast } from "react-toastify";

export default function CaregiverChild() {
  const location = useLocation();
  const [childList, setChildList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(
    new Array(childList.length).fill(false)
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = localStorage.getItem("token");

  const handleEditClick = (index) => {
    setIsEditing((prev) => {
      const newEditingState = [...prev];
      newEditingState[index] = !newEditingState[index];
      return newEditingState;
    });
  };
  const navigate = useNavigate();
  const handleHistory = (item) => {
    const historyLink = `/child/history?id=${item?.childId}`;
    navigate(historyLink);
  };

  //Form handler start
  const updateInitialValue = {
    detail: childList?.map((val) => {
      return {
        ...val,
        childId: val?.childId,
        dateOfBirth: val?.dateOfBirth,
        firstname: val?.firstname,
        lastnames: val?.lastnames,
        otherNames: val?.otherNames,
      };
    }) || [
      {
        childId: 1,
        firstname: "Baby",
        lastnames: "Pro 2",
        otherNames: "",
        dateOfBirth: "2023-09-13",
      },
    ],
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
    },
    enableReinitialize: true,
    validateOnChange: false,
  });
  const handleSubmit = async (values) => {
    const allValue = {
      childId: values?.childId,
      dateOfBirth: values?.dateOfBirth,
      firstname: values?.firstname,
      lastnames: values?.lastnames,
      otherNames: values?.otherNames,
    };
    setBtnLoading(true);
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/childs/${values?.childId}`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.put(apiUrl, allValue, config);
      if (response.status === 200) {
        setBtnLoading(false);
        toast.success("Saved Sucessfully");
        if (id) {
          fetchData(id);
        }
      }
    } catch (error) {
      setBtnLoading(false);
      toast.error("Something went wrong");
    }
  };
  

  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/caregiver/${id}/childs`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setChildList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong");
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);
  

  const onChange = (e, i, fieldName, value) => {
    formik.setFieldValue(`detail[${i}].${fieldName}`, e.target.value);
  };
  const inputConfigs = [
    {
      label: "Child ID",
      id: "childId",
      name: "childId",
      type: "text",
      placeholder: "Enter ID",
    },
    {
      label: "First Name",
      id: "firstname",
      name: "firstname",
      type: "text",
      placeholder: "First Name",
    },
    {
      label: "Last Name",
      id: "lastnames",
      name: "lastnames",
      type: "text",
      placeholder: "Last Name",
    },
    {
      label: "Caregiver ID",
      id: "caregiverID",
      name: "caregiverID",
      type: "text",
      placeholder: "Caregiver ID",
    },
    {
      label: "Others Names",
      id: "otherNames",
      name: "otherNames",
      type: "text",
      placeholder: "Others Names",
    },
    {
      label: "Date of Birth",
      id: "dateOfBirth",
      name: "dateOfBirth",
      type: "date",
      placeholder: "Date of Birth",
    },
  ];
  console.log("formik-->values", formik.values);
  return (
    <div className="mt-12">
      {loading && <Loader />}
      <div className="mt-16 w-full bg-white rounded-2xl flex items-center text-gray-700">
        <Link
          to={`/caregiver?id=${id}`}
          className={`w-1/2 text-center py-2 cursor-pointer rounded-tl-2xl rounded-bl-2xl transition duration-300 ${
            location.pathname === `/caregiver`
              ? "bg-blue-500 text-white font-semibold hover:shadow-md shadow-md"
              : "hover:bg-gray-100 text-sm hover:shadow-md font-semibold shadow-md"
          }`}
        >
          Caregiver
        </Link>
        <Link
          to={`/child?id=${id}`}
          className={`w-1/2 text-center py-2 cursor-pointer rounded-tr-2xl rounded-br-2xl transition duration-300 ${
            location.pathname === "/child"
              ? "bg-blue-500 text-white font-semibold hover:shadow-md shadow-md"
              : "hover:bg-gray-100 text-sm hover:shadow-md font-semibold shadow-md"
          }`}
        >
          Child
        </Link>
      </div>
      {formik.values?.detail?.map((item, idx) => (
        <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-4">
          {console.log("item", item)}
          <form onSubmit={formik.handleSubmit}>
            <div className="p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 items-center">
                <div className="col-span-1">
                  <div className="w-32 h-32 bg-black flex items-center justify-center rounded-full">
                    {item?.photoUrl ? (
                      <img
                        src={item?.photoUrl}
                        alt="User"
                        className="w-32 h-32 rounded-full"
                      />
                    ) : (
                      <AiOutlineUser size={64} color="white" />
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2 text-xs">
                    {inputConfigs.map((config, index) => (
                      <div
                        key={index}
                        className="col-span-1 sm:col-span-1 md:col-span-1 lg:col-span-1 flex items-center min-w-[50px]"
                      >
                        <label>{config.label}:</label>
                        <input
                          className="ml-2 h-8"
                          type={config.type}
                          id={config.id}
                          name={config.name}
                          placeholder={config.placeholder}
                          value={formik.values?.detail?.[idx][config.name]}
                          onChange={(e) => onChange(e, idx, config.name)}
                          disabled={!isEditing[idx]}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
           
           
            <div className="flex flex-col sm:flex-row items-center space-y-4 sm:space-y-0 sm:space-x-4 p-3">
              <button
                className="bg-white hover:bg-gray-200 text-black py-2 px-4 rounded-lg flex items-center"
                type="button"
                onClick={() => {
                  handleHistory(item);
                }}
              >
                <CiTimer className="mr-2" /> History
              </button>
              <div className="flex items-center space-y-0 space-x-4">
                <button
                  className="bg-blue-600 hover:bg-blue-900 text-white py-2 px-4 rounded-lg flex items-center"
                  type="button"
                  onClick={() => handleEditClick(idx)}
                >
                  <FaEdit className="mr-2" /> Edit
                </button>
                <button
                  className={`bg-green-600 hover:bg-green-900 text-white py-2 px-4 rounded-lg flex items-center ${
                    btnLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  type="button"
                  onClick={() => handleSubmit(item)}
                  disabled={btnLoading}
                >
                  {btnLoading ? (
                    <div className="flex items-center">
                      <div className="animate-spin mr-2 h-5 w-5 border-t-2 border-b-2 border-green-600 rounded-full"></div>
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <>
                      <FaSave className="mr-2" />
                      Save
                    </>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      ))}
    </div>
  );
}
