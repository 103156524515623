import React from "react";
import {HiOutlineCog, HiOutlineCube, HiOutlineViewGrid} from 'react-icons/hi'
import {GiCash} from 'react-icons/gi'
import {AiFillBell} from 'react-icons/ai'
export const Dashboard_Sidebar_Links =[
    {
        key:'dashboard',
        label:'Dashboard',
        path:'/',
        icon:<HiOutlineViewGrid/>
    },
    {
        key:'enrollment',
        label:'Enrollment Caregiver',
        path:'/emrollment/caregiver',
        icon:<HiOutlineCube/>
    },
    {
        key:'enrollmentOfficer',
        label:'Enrollment Officer',
        path:'/emrollment/officer',
        icon:<HiOutlineCube/>
    },
    {
        key:'transfer',
        label:'Cash Transfer',
        path:'/transfer',
        icon:<GiCash/>
    },
    {
        key:'reminder',
        label:'Reminder',
        path:'/reminder',
        icon:<AiFillBell/>
    },
    {
        key:'administration',
        label:'Administration',
        path:'/administration',
        icon:<AiFillBell/>
    },
]

export const Dashboard_Sidebar_Bottom_Links =[
    {
        key:'settings',
        label:'Settings',
        // path:'/settings',
        icon:<HiOutlineCog/>
    },
]