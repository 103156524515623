import React, { useEffect, useState } from "react";
import HeaderTopBar from "./HeaderTopBar";
import { FaFileSignature } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { toast } from "react-toastify";
import { useFormik } from "formik";

export default function Addministration() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [lgaList, setLgaList] = useState([]);
  const [wardList, setWardList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(null);
  const [name, setName] = useState(null);
  let dropDownData;
  if (name === "State") {
    dropDownData = regionList;
  } else if (name === "LGA") {
    dropDownData = stateList;
  } else if (name === "Ward" || name === "HFN") {
    dropDownData = lgaList;
  }

  let dropDownValue;
  if (name === "State") {
    dropDownValue = isUpdate?.region;
  } else if (name === "LGA") {
    dropDownValue = isUpdate?.state;
  } else if (name === "Ward" || name === "HFN") {
    dropDownValue = isUpdate?.lga;
  }

  //Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const [currentStatePage, setCurrentStatePage] = useState(1);
  const [currentLgaPage, setCurrentLgaPage] = useState(1);
  const [currentWardPage, setCurrentWardPage] = useState(1);
  const [currentHospitalPage, setCurrentHospitalPage] = useState(1);
  const itemsPerPage = 4;

  const paginatedRegionData = regionList.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const paginatedStateData = stateList.slice(
    (currentStatePage - 1) * itemsPerPage,
    currentStatePage * itemsPerPage
  );
  const paginatedLgaData = lgaList.slice(
    (currentLgaPage - 1) * itemsPerPage,
    currentLgaPage * itemsPerPage
  );
  const paginatedWardData = wardList.slice(
    (currentWardPage - 1) * itemsPerPage,
    currentWardPage * itemsPerPage
  );
  const paginatedHfnData = hospitalList.slice(
    (currentHospitalPage - 1) * itemsPerPage,
    currentHospitalPage * itemsPerPage
  );
  const totalRegionPages = Math.ceil(regionList?.length / itemsPerPage);
  const totalStatePages = Math.ceil(stateList?.length / itemsPerPage);
  const totalLgaPages = Math.ceil(lgaList?.length / itemsPerPage);
  const totalWardPages = Math.ceil(wardList?.length / itemsPerPage);
  const totalHospitlPages = Math.ceil(hospitalList?.length / itemsPerPage);
  const handleRegionChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handleStateChange = (newPage) => {
    setCurrentStatePage(newPage);
  };
  const handleLgaChange = (newPage) => {
    setCurrentLgaPage(newPage);
  };
  const handleWardChange = (newPage) => {
    setCurrentWardPage(newPage);
  };
  const handleHospitalChange = (newPage) => {
    setCurrentHospitalPage(newPage);
  };

  const updateInitialValue = {
    name: isUpdate ? isUpdate?.name : "",
    id: isUpdate ? dropDownData?.find((item) => item.id === dropDownValue) : 0,
    region: paginatedRegionData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    state: paginatedStateData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    lga: paginatedLgaData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    ward: paginatedWardData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
    hospital: paginatedHfnData?.map((item) => {
      return {
        ...item,
        code: item?.id,
        name: item?.name,
      };
    }),
  };
  //  ---- Start Formik Object -----
  const formik = useFormik({
    initialValues: updateInitialValue,
    onSubmit: (values) => {
      //   handleSubmit(values);
    },
    enableReinitialize: true,
    validateOnChange: false,
  });

  //Api's Url
  const regionUrl = `${process.env.REACT_APP_MAIN_URL}/users/region/`;
  const stateUrl = `${process.env.REACT_APP_MAIN_URL}/users/states/`;
  const lgaUrl = `${process.env.REACT_APP_MAIN_URL}/users/lga/`;
  const wardUrl = `${process.env.REACT_APP_MAIN_URL}/users/ward/`;
  const hospitalUrl = `${process.env.REACT_APP_MAIN_URL}/users/h_f_n/`;

  const handleEditClick = async () => {
    if (name) {
      const allValue = {
        name: formik.values?.name,
        ...(name === "State"
          ? {
              region:
                Number(formik.values?.id?.id) || Number(formik.values?.id),
            }
          : {}),
        ...(name === "LGA"
          ? {
              state: Number(formik.values?.id?.id) || Number(formik.values?.id),
            }
          : {}),
        ...(name === "Ward"
          ? { lga: Number(formik.values?.id?.id) || Number(formik.values?.id) }
          : {}),
        ...(name === "HFN"
          ? { lga: Number(formik.values?.id?.id) || Number(formik.values?.id) }
          : {}),
      };
      const endpoints = {
        Region: {
          url: `${process.env.REACT_APP_MAIN_URL}/users/region/${isUpdate?.id}/`,
          onSuccess: () => {
            getRegion();
          },
        },
        State: {
          url: `${process.env.REACT_APP_MAIN_URL}/users/states/${isUpdate?.id}/`,
          onSuccess: () => {
            getState();
          },
        },
        LGA: {
          url: `${process.env.REACT_APP_MAIN_URL}/users/lga/${isUpdate?.id}/`,
          onSuccess: () => {
            getLga();
          },
        },
        Ward: {
          url: `${process.env.REACT_APP_MAIN_URL}/users/ward/${isUpdate?.id}/`,
          onSuccess: () => {
            getWard();
          },
        },
        HFN: {
          url: `${process.env.REACT_APP_MAIN_URL}/users/h_f_n/${isUpdate?.id}/`,
          onSuccess: () => {
            getHospitalFacility();
          },
        },
      };
      const { url, onSuccess } = endpoints[name];
      if (url) {
        try {
          setLoading(true);
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.put(url, allValue, config);
          if (response.status === 200) {
            setLoading(false);
            toast.success("Update Successfully");
            setName(null);
            formik.resetForm();
            setIsUpdate(null);
            onSuccess();
          }
        } catch (error) {
          setLoading(false);
          toast.error("Something went wrong");
        }
      }
      setShowModal(false);
    }
  };

  //Get Region
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Get Dashboard data
  const getRegion = () => {
    setLoading(true);
    axios
      .get(regionUrl, axiosConfig)
      .then((response) => {
        setRegionList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in Region");
        setLoading(false);
      });
  };
  //Get State data
  const getState = () => {
    setLoading(true);
    axios
      .get(stateUrl, axiosConfig)
      .then((response) => {
        setStateList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in State");
        setLoading(false);
      });
  };
  //Get LGA data
  const getLga = () => {
    setLoading(true);
    axios
      .get(lgaUrl, axiosConfig)
      .then((response) => {
        setLgaList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in LGA");
        setLoading(false);
      });
  };

  //Get Ward
  const getWard = () => {
    setLoading(true);
    axios
      .get(wardUrl, axiosConfig)
      .then((response) => {
        setWardList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in ward");
        setLoading(false);
      });
  };
  const getHospitalFacility = () => {
    setLoading(true);
    axios
      .get(hospitalUrl, axiosConfig)
      .then((response) => {
        setHospitalList(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong in Hospital Facility");
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegion();
    getState();
    getLga();
    getWard();
    getHospitalFacility();
  }, []);

  const onRegionChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`region[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`region[${i}].${fieldName}`, e.value);
  };
  const onStateChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`state[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`state[${i}].${fieldName}`, e.value);
  };
  const onLgaChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`lga[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`lga[${i}].${fieldName}`, e.value);
  };
  const onWardChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(`ward[${i}].${fieldName}`, e.target.value);
    }
    formik.setFieldValue(`ward[${i}].${fieldName}`, e.value);
  };
  const onhospitalChange = (e, i, fieldName) => {
    if (e.target?.value) {
      return formik.setFieldValue(
        `hospital[${i}].${fieldName}`,
        e.target.value
      );
    }
    formik.setFieldValue(`hospital[${i}].${fieldName}`, e.value);
  };

  //Delete Functionality
  const handleDeleteRegion = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/users/region/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("Region Deleted successfully");
          getRegion();
          getState();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteState = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/users/states/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("State Deleted successfully");
          getState();
          getLga();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteLga = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/users/lga/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("LGA Deleted successfully");
          getLga();
          getWard();
          getHospitalFacility();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteWard = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/users/ward/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("Ward Deleted successfully");
          getWard();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };
  const handleDeleteHospitalFacility = (valId) => {
    const deleteUrl = `${process.env.REACT_APP_MAIN_URL}/users/h_f_n/${valId}/`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .delete(deleteUrl, config)
      .then((response) => {
        if (response.status === 204) {
          toast.success("Hospital Deleted successfully");
          getHospitalFacility();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error("Something went wrong");
      });
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setName(null);
    formik.resetForm();
    setIsUpdate(null);
  };

  const handleSave = async () => {
    const allValue = {
      name: formik.values?.name,
      ...(name === "State" ? { region: Number(formik.values?.id) } : {}),
      ...(name === "LGA" ? { state: Number(formik.values?.id) } : {}),
      ...(name === "Ward" ? { lga: Number(formik.values?.id) } : {}),
      ...(name === "HFN" ? { lga: Number(formik.values?.id) } : {}),
    };
    if (!allValue?.name) {
      return toast.error("Name is Required");
    }
    const endpoints = {
      Region: {
        url: `${process.env.REACT_APP_MAIN_URL}/users/region/`,
        onSuccess: () => {
          getRegion();
        },
      },
      State: {
        url: `${process.env.REACT_APP_MAIN_URL}/users/states/`,
        onSuccess: () => {
          getState();
        },
      },
      LGA: {
        url: `${process.env.REACT_APP_MAIN_URL}/users/lga/`,
        onSuccess: () => {
          getLga();
        },
      },
      Ward: {
        url: `${process.env.REACT_APP_MAIN_URL}/users/ward/`,
        onSuccess: () => {
          getWard();
        },
      },
      HFN: {
        url: `${process.env.REACT_APP_MAIN_URL}/users/h_f_n/`,
        onSuccess: () => {
          getHospitalFacility();
        },
      },
    };
    if (name === "Region") {
      const regionNames = regionList?.map((item) => item.name.toLowerCase()); // Convert to lowercase
      const inputValue = formik.values.name.toLowerCase(); // Convert to lowercase
    
      if (regionNames.includes(inputValue)) {
        return toast.error("Region name already exists");
      }
    }
    
    
    const { url, onSuccess } = endpoints[name];
    if (url) {
      try {
        setLoading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.post(url, allValue, config);
        if (response.status === 201) {
          setLoading(false);
          toast.success("Saved Successfully");
          formik.resetForm();
          onSuccess();
        }
      } catch (error) {
        setLoading(false);
        toast.error("Something went wrong");
      }
    }
    setShowModal(false);
  };

  return (
    <div className="mt-12">
      <HeaderTopBar
        icon={<FaFileSignature className="text-gray-700 text-2xl mr-2" />}
        title={"Administration"}
        subtitle={"locations"}
      />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        <div className="relative">
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-4">
                <p className="font-semibold text-sm">Region</p>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("Region");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.region?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onRegionChange(e, idx, "code")}
                          value={formik.values.region?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onRegionChange(e, idx, "name")}
                          value={formik.values.region?.[idx]?.name}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("Region");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteRegion(val.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedRegionData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleRegionChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {/* {Array.from({ length: totalRegionPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleRegionChange(index + 1)}
                    className={`${
                      currentPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))} */}

                {currentPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleRegionChange(currentPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleRegionChange(currentPage + 1)}
                    disabled={currentPage === totalRegionPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>

          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>

        <div className="relative">
          {/* State portion */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-4">
                <p className="font-semibold text-sm">State</p>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("State");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.state?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onStateChange(e, idx, "code")}
                          value={formik.values.state?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onStateChange(e, idx, "name")}
                          value={formik.values.state?.[idx]?.name}
                          disabled="true"
                        />
                      </td>

                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded "
                            onClick={() => {
                              setName("State");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteState(val.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedStateData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentStatePage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleStateChange(currentStatePage - 1)}
                    disabled={currentStatePage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {/* {Array.from({ length: totalStatePages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleStateChange(index + 1)}
                    className={`${
                      currentStatePage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))} */}

                {currentStatePage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleStateChange(currentStatePage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleStateChange(currentStatePage + 1)}
                    disabled={currentStatePage === totalStatePages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>

        <div className="relative">
          {/* LGA Start */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-4">
                <p className="font-semibold text-sm">LGA</p>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("LGA");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.lga?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onLgaChange(e, idx, "code")}
                          value={formik.values.lga?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onLgaChange(e, idx, "name")}
                          value={formik.values.lga?.[idx]?.name}
                          disabled="true"
                        />
                      </td>

                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("LGA");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteLga(val.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedLgaData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentLgaPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleLgaChange(currentLgaPage - 1)}
                    disabled={currentLgaPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {/* {Array.from({ length: totalLgaPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleLgaChange(index + 1)}
                    className={`${
                      currentLgaPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))} */}

                {currentLgaPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleLgaChange(currentLgaPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleLgaChange(currentLgaPage + 1)}
                    disabled={currentLgaPage === totalLgaPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>

        <div className="relative">
          {/* Ward */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-4">
                <p className="font-semibold text-sm">Ward</p>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("Ward");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.ward?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onWardChange(e, idx, "code")}
                          value={formik.values.ward?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onWardChange(e, idx, "name")}
                          value={formik.values.ward?.[idx]?.name}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("Ward");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteWard(val?.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedWardData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentWardPage > 1 && (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentWardPage - 1)}
                    disabled={currentWardPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {/* {Array.from({ length: totalWardPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleWardChange(index + 1)}
                    className={`${
                      currentWardPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))} */}

                {currentWardPage < 1 ? (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentWardPage + 1)}
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => handleWardChange(currentWardPage + 1)}
                    disabled={currentWardPage === totalWardPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>

        <div className="relative">
          {/* Hospital facility Name */}
          <div className="bg-white shadow-md p-4 rounded-md h-[510px]">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="mb-4 md:mb-0 md:mr-4">
                <p className="font-semibold text-sm">Hospital Facility Name</p>
              </div>
              <div className="flex flex-col md:flex-row items-center">
                <button
                  className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded mb-2 md:mb-0 md:mr-2"
                  onClick={() => {
                    openModal();
                    setName("HFN");
                  }}
                >
                  Create
                </button>
                <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-semibold py-2 px-4 rounded">
                  Search
                </button>
              </div>
            </div>
            <br />
            <hr />
            <div className="mt-2">
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100 rounded-sm">
                    <th className="p-2">Code</th>
                    <th className="p-2">Name</th>
                    <th className="p-2">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formik.values.hospital?.map((val, idx) => (
                    <tr>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="code"
                          id="code"
                          name="code"
                          onChange={(e) => onhospitalChange(e, idx, "code")}
                          value={formik.values.hospital?.[idx]?.code}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2">
                        <input
                          type="text"
                          className="w-full border border-gray-300 p-1 rounded"
                          placeholder="Name"
                          id="name"
                          name="name"
                          onChange={(e) => onhospitalChange(e, idx, "name")}
                          value={formik.values.hospital?.[idx]?.name}
                          disabled="true"
                        />
                      </td>
                      <td className="p-2 text-center">
                        <>
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => {
                              setName("HFN");
                              setIsUpdate(val);
                              openModal();
                            }}
                          >
                            {loading ? (
                              <svg
                                className="animate-spin h-5 w-5 mr-3 border-t-2 border-b-2 border-blue-900 rounded-full"
                                viewBox="0 0 24 24"
                              ></svg>
                            ) : (
                              <BiEdit size={20} />
                            )}
                          </button>
                          <button
                            className="bg-red-500 hover:bg-red-600 text-white font-semibold py-1 px-2 rounded"
                            onClick={() => handleDeleteHospitalFacility(val.id)}
                            type="button"
                          >
                            <AiFillDelete size={20} />
                          </button>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {paginatedHfnData?.length > 0 ? (
              <div className="flex items-center justify-center mt-8 space-x-4">
                {currentHospitalPage > 1 && (
                  <button
                    type="button"
                    onClick={() =>
                      handleHospitalChange(currentHospitalPage - 1)
                    }
                    disabled={currentHospitalPage === 1}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Previous
                  </button>
                )}

                {/* {Array.from({ length: totalHospitlPages }, (_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => handleHospitalChange(index + 1)}
                    className={`${
                      currentHospitalPage === index + 1
                        ? "bg-pink-600 text-white font-bold py-2 px-4 rounded"
                        : "bg-pink-200 hover:bg-pink-300 text-pink-800 font-bold py-2 px-4 rounded"
                    } focus:outline-none focus:ring focus:border-pink-300`}
                  >
                    {index + 1}
                  </button>
                ))} */}

                {currentHospitalPage < 1 ? (
                  <button
                    type="button"
                    onClick={() =>
                      handleHospitalChange(currentHospitalPage + 1)
                    }
                    disabled
                    className="bg-purple-300 text-purple-600 font-bold py-2 px-4 rounded cursor-not-allowed opacity-50"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() =>
                      handleHospitalChange(currentHospitalPage + 1)
                    }
                    disabled={currentHospitalPage === totalHospitlPages}
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring focus:border-purple-300"
                  >
                    Next
                  </button>
                )}
              </div>
            ) : (
              <div className="flex items-center justify-center mt-8 text-red-500 text-lg font-bold">
                No Data Found
              </div>
            )}
          </div>
          {loading && (
            <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-75">
              <div className="animate-spin rounded-full border-t-4 border-blue-500 border-solid h-12 w-12"></div>
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">
                  {isUpdate ? "Update" : "Save"}
                </p>
                <button className="modal-close" onClick={closeModal}>
                  X
                </button>
              </div>
              <input
                id="name"
                name="name"
                labelFor="name"
                type="text"
                className="w-full border p-2 mb-4"
                placeholder="Enter Name"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {name === "State" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a Region"}
                  </option>
                  {regionList.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              )}

              {name === "LGA" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a State"}
                  </option>
                  {stateList.map((lga) => (
                    <option key={lga.id} value={lga.id}>
                      {lga.name}
                    </option>
                  ))}
                </select>
              )}

              {name === "Ward" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a LGA"}
                  </option>
                  {lgaList.map((ward) => (
                    <option key={ward.id} value={ward.id}>
                      {ward.name}
                    </option>
                  ))}
                </select>
              )}

              {name === "HFN" && (
                <select
                  id="id"
                  name="id"
                  className="w-full border p-2 mb-4"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                >
                  <option value="">
                    {isUpdate ? formik.values?.id?.name : "Select a LGA"}
                  </option>
                  {lgaList.map((lga) => (
                    <option key={lga.id} value={lga.id}>
                      {lga.name}
                    </option>
                  ))}
                </select>
              )}

              <button
                className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                onClick={isUpdate ? handleEditClick : handleSave}
                type="button"
                disabled={loading}
              >
                {loading ? (
                  <svg
                    className="animate-spin h-5 w-5 mr-3"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V2C5.373 2 2 5.373 2 10h2zm2 0a6 6 0 016-6V4a4 4 0 00-4 4H6zm6 0a6 6 0 016 6h2a8 8 0 00-8-8V2zm6 0a10 10 0 00-10 10h2a8 8 0 018-8z"
                    ></path>
                  </svg>
                ) : (
                  `${isUpdate ? "Update" : "Save"} `
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
