import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

export default function ChildHistory() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [child, setChild] = useState(null);
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const fetchData = (id) => {
    const apiUrl = `${process.env.REACT_APP_MAIN_URL}/caregivers/childs/${id}`;
    setLoading(true);
    axios
      .get(apiUrl, axiosConfig)
      .then((response) => {
        setChild(response.data);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);
  return (
    <div className="mt-16">
      <div className="max-w-5xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden mt-4">
        <table className="min-w-full bg-white border border-gray-200 shadow-lg rounded-lg overflow-hidden">
          <thead className="bg-blue-500 text-white">
            <tr className="text-sm">
              <th className="px-4 py-2 text-left">Vaccination Number</th>
              <th className="px-4 py-2 text-left">Vaccination Name</th>
              <th className="px-4 py-2 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-left text-xs">
              <td className="px-4 py-2 border-b border-gray-200">1</td>
              <td className="px-4 py-2 border-b border-gray-200">
                COVID-19 Vaccine
              </td>
              <td className="px-4 py-2 border-b border-gray-200">2023-10-17</td>
            </tr>
            <tr className="text-left text-xs bg-gray-50">
              <td className="px-4 py-2 border-b border-gray-200">2</td>
              <td className="px-4 py-2 border-b border-gray-200">Flu Shot</td>
              <td className="px-4 py-2 border-b border-gray-200">2023-11-15</td>
            </tr>
            {/* Add more rows as needed */}
          </tbody>
        </table>
      </div>
    </div>
  );
}
