import React from "react";
import { FcBullish } from "react-icons/fc";
import {
  Dashboard_Sidebar_Bottom_Links,
  Dashboard_Sidebar_Links,
} from "../../lib/consts/navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { HiOutlineLogout } from "react-icons/hi";
import logo from "../Static/logo.png"

const linkClasses =
  "flex items-center gap-2 font-light px-3 py-2 hover:bg-neutral-700 hover:no-underline active:bg-neutral-600 rounded-sm text-base";

export default function Sidebar() {
  const navigation = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigation("/login");
  };
  return (
    <div className="fixed top-0 left-0 h-screen w-60 bg-neutral-900 text-white flex flex-col md:pt-8 md:p-3 sm:p-3 sm:pt-10 lg:p-3 lg:pt-0 pt-12 pl-2">
      <div className="flex items-center gap-2 px-1 py-3">
        <img
          src={logo}
          alt="Your Alt Text"
          style={{ width: 24, height: 24 }}
        />
        <span className="text-neutral-100 text-lg">CMS-DSI</span>
      </div>

      <div className="flex-1">
        {Dashboard_Sidebar_Links?.map((item) => (
          <SidebarLink key={item.key} item={item} />
        ))}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
        <div
          className={classNames("text-red-500 cursor-pointer", linkClasses)}
          onClick={handleLogout}
        >
          <span className="text-xl">
            <HiOutlineLogout />
          </span>
          Logout
        </div>
      </div>
    </div>
  );
}

function SidebarLink({ item }) {
  const { pathname } = useLocation();
  return (
    <Link
      to={item.path}
      className={classNames(
        pathname === item.path
          ? "text-white bg-neutral-700"
          : "text-neutral-400",
        linkClasses
      )}
    >
      <span className="text-xl">{item.icon}</span>
      {item.label}
    </Link>
  );
}
